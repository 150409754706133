import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import MapPage from './pages/MapPage';
import BlogPage from './pages/BlogPage';
import BlogPost from './components/BlogPost';
import Petrer from './asserts/Petrer.jpg'
import Tibi from './asserts/Tibi.jpg'
import Cofrentes from './asserts/Cofrentes.jpg'
import Orxeta from './asserts/Orxeta.jpeg'
import Finestrat from './asserts/Finestrat.jpeg'
import Mina from './asserts/Mina.png'
import Villamarchante from './asserts/montehorquera.png'
import Villarroya from './asserts/Villaroya.png'
import AvisoLegal from './components/AvisoLegal';
import TerminosCondiciones from './components/TerminosCondiciones';
import PoliticaPrivacidad from './components/PoliticaPrivacidad';
import PoliticaCookies from './components/PoliticaCookies';
import iddgnisita from './asserts/iddgnisita.jpg';
import azufre from './asserts/azufre.jpg'
import compostela from './asserts/jacinto.jpg'
import calcita from './asserts/calcita.jpg'
import yeso from './asserts/yeso.jpg'
import dolomita from './asserts/dolimta.jpg'
import amatista from './asserts/amatista.jpg'
import silex from './asserts/silex.jpg'
import cuarzo from './asserts/cuarzo.jpg'
import faro from './asserts/faro del albir.png'
import azuita from './asserts/azurita.png'
import oliverita from './asserts/oliverita.png'
import argonito from './asserts/argonitos.jpg'
import './styles/global.css';

const App = () => {

  const posts = [
    {
      id: 'Olivino y Iddingsita Volcán Cerro de Agras, Cofrentes',
      title: 'Olivino y Iddingsita Volcán Cerro de Agras, Cofrentes',
      date: '2024-8-5',
      image: Cofrentes,
      excerpt: 'Un volcán de gran importancia geológica, conocido por sus yacimientos de olivino e iddingsita',
      content: `
            El Cerro de Agras, situado en Cofrentes, Valencia, es un volcán de gran importancia geológica, conocido por sus yacimientos de olivino e iddingsita. Este volcán, uno de los pocos activos en la península ibérica en épocas geológicas pasadas, es una muestra destacada del vulcanismo en la región.

El olivino es un mineral que se encuentra comúnmente en rocas ígneas, especialmente en basaltos, y es reconocido por su color verde oliva y su estructura cristalina. En el Cerro de Agras, el olivino se presenta en forma de cristales bien formados, lo que lo convierte en un atractivo tanto para geólogos como para coleccionistas.

La iddingsita es un mineral de alteración que se forma a partir del olivino bajo condiciones de baja temperatura e hidratación, y es menos común que su mineral precursor. En Cofrentes, la iddingsita aparece como un mineral terroso de color marrón o rojizo, destacando en las zonas donde el olivino ha experimentado procesos de alteración.

Estos minerales son de particular interés porque ofrecen una visión clara de los procesos post-eruptivos que han afectado al Cerro de Agras. La transformación de olivino en iddingsita es un indicador de la interacción entre el magma y el entorno en el que se enfría, proporcionando información valiosa sobre la historia geológica del volcán.
        `
    },
    {

      id: 'Jacintos de compostela en la carretera de Orxeta-Finestrat',
      title: 'Jacintos de compostela en la carretera de Orxeta-Finestrat',
      date: '2024-03-15',
      image: Finestrat,
      excerpt: 'Una variedad de cuarzo rojo que debe su coloración a la presencia de óxidos de hierro',
      content: `
            A lo largo de la carretera que conecta Orxeta y Finestrat, en la provincia de Alicante, se encuentran ricos yacimientos de Jacintos de Compostela. Estos cristales, una variedad de cuarzo rojo que debe su coloración a la presencia de óxidos de hierro, son altamente apreciados tanto por su belleza como por su valor mineralógico.

Los Jacintos de Compostela se forman en rocas sedimentarias, principalmente en arenas y arcillas, donde los procesos de cementación y oxidación les confieren su característico tono rojizo, que puede variar desde un naranja intenso hasta un rojo oscuro. Estos cristales suelen tener un hábito prismático, con caras bien definidas y bordes afilados, lo que les otorga un gran atractivo visual.

La carretera de Orxeta-Finestrat es conocida entre coleccionistas y geólogos por la calidad excepcional de los Jacintos de Compostela que se encuentran en la zona. Los ejemplares extraídos aquí son valorados por su tamaño, brillo y color, haciéndolos muy deseados en el ámbito de la mineralogía.

Históricamente, estos cristales han sido recolectados no solo por su belleza, sino también por su supuesta capacidad de atraer la buena suerte, lo que les ha conferido un valor cultural además del científico. Aunque la extracción en esta área ha sido a pequeña escala, los Jacintos de Compostela de Orxeta-Finestrat han contribuido al conocimiento geológico regional, permitiendo estudiar los procesos sedimentarios y diagenéticos que dan lugar a la formación de estos cristales.
        `
    },
    {
      id: 'Argonitos en Loma Badá, Petrer',
      title: 'Argonitos en Loma Badá, Petrer',
      date: '2024-07-15',
      image: Petrer,
      excerpt: 'El aragonito es una de las tres formas cristalinas en las que se puede encontrar el carbonato de calcio',
      content: `
            Loma Badá, ubicada en la localidad de Petrer, en la provincia de Alicante, es conocida por sus yacimientos de minerales, en particular de aragonito. El aragonito es una de las tres formas cristalinas en las que se puede encontrar el carbonato de calcio, siendo las otras dos la calcita y la vaterita. Este mineral se caracteriza por su estructura ortorrómbica y su hábito cristalino, que a menudo forma cristales aciculares o prismáticos.

En Loma Badá, el aragonito se presenta en una variedad de formas y colores, que van desde el blanco puro hasta tonos más oscuros, dependiendo de las impurezas presentes durante su formación. Los yacimientos en esta zona son de particular interés para los coleccionistas y geólogos debido a la calidad y el tamaño de los cristales, que pueden alcanzar dimensiones considerables y mostrar una transparencia notable.

La extracción del aragonito en Loma Badá ha sido objeto de estudio tanto por su valor mineralógico como por su importancia en la geología regional. Este mineral se forma en ambientes geológicos específicos, generalmente en condiciones de baja temperatura y alta presión, lo que lo convierte en un indicador clave de procesos geológicos en la región.
        `
    },
    {
      id: 'Azufre en la mina San Francisco, Tibi',
      title: 'Azufre en la mina San Francisco, Tibi',
      date: '2024-08-10',
      image: Tibi,
      excerpt: 'El azufre en su forma nativa, se caracteriza por su distintivo color amarillo y su fragilidad.',
      content: `
            La mina San Francisco, ubicada en Tibi, Alicante, es un sitio de notable relevancia debido a sus ricos yacimientos de azufre. Este mineral, fundamental en diversas industrias, se presenta en esta mina en su forma nativa, caracterizada por su distintivo color amarillo y su fragilidad.

El azufre de la mina San Francisco ha sido históricamente importante tanto para la economía local como para estudios geológicos. Su presencia en Tibi se debe a procesos geotérmicos y volcánicos que facilitaron la acumulación de azufre en esta zona, lo que convierte a la mina en un lugar de interés para geólogos y mineralogistas.

Los yacimientos de azufre en la mina San Francisco se distinguen por la pureza del mineral, que en algunos casos alcanza niveles elevados, haciendo que los cristales sean muy apreciados. Además, este azufre ha sido utilizado tradicionalmente en la producción de ácido sulfúrico, en la vulcanización del caucho, y en la fabricación de productos químicos.
        `
    },
    {
      id: 'Calcita en el deposito de Keuper, Carretera Orxeta-Finestrat',
      title: 'Calcita en el deposito de Keuper, Carretera Orxeta-Finestrat',
      date: '2024-04-05',
      image: Orxeta,
      excerpt: 'Calcita, un mineral de los más comunes en la corteza terrestre, destacan por su pureza y brillo',
      content: `
            El depósito de Keuper, situado a lo largo de la carretera que conecta Orxeta y Finestrat en la provincia de Alicante, es conocido por sus yacimientos de calcita. Este mineral, uno de los más comunes en la corteza terrestre, se presenta en este lugar en forma de cristales bien formados que destacan por su pureza y brillo.

La calcita es un mineral de carbonato de calcio que se encuentra en una amplia variedad de formas y colores, dependiendo de las impurezas y las condiciones de formación. En el depósito de Keuper, la calcita aparece típicamente en tonos que van desde el blanco translúcido hasta el amarillo pálido, con cristales que pueden adoptar formas romboédricas o escalenoédricas, las cuales son características de este mineral.

El depósito de Keuper es parte de una formación geológica más amplia que se desarrolló durante el período Triásico, cuando se acumularon grandes cantidades de sedimentos en condiciones marinas y lacustres. La calcita en esta zona se originó principalmente a partir de la precipitación química de carbonato de calcio en ambientes evaporíticos, lo que ha dado lugar a los depósitos masivos que se observan hoy en día.

Este yacimiento es de especial interés tanto para geólogos como para coleccionistas, ya que ofrece una excelente oportunidad para estudiar los procesos sedimentarios y diagenéticos que ocurren en ambientes evaporíticos. Además, la calcita extraída de esta área es valorada por su uso en la industria, especialmente en la producción de cemento, cal y otros materiales de construcción.


        `
    },
    {
      id: "Azurita y Olivenita Monte Horquera, Villamarchante",
      title: "Azurita y Olivenita Monte Horquera, Villamarchante",
      date: "2024-9-14",
      image: Villamarchante,
      excerpt: "Un yacimiento mineral excepcional con abundancia de azurita y olivenita en Monte Horquera, Villamarchante.",
      content: `
      El Monte Horquera, ubicado en Villamarchante, Valencia, es reconocido por sus yacimientos minerales, especialmente por la presencia de azurita y olivenita. Este sitio es una referencia geológica importante debido a la riqueza y diversidad de los minerales que se pueden encontrar en su entorno.

      La azurita, un mineral de cobre caracterizado por su distintivo color azul profundo, se forma en zonas de oxidación de los depósitos de cobre. En Monte Horquera, la azurita se presenta en cristales bien desarrollados y en masas nodulares, lo que la convierte en un atractivo para los coleccionistas y estudiosos de la mineralogía.

      La olivenita, otro mineral de cobre, es menos común pero igualmente apreciada. Se reconoce por su color verde oliva o marrón, y es un producto de la alteración hidrotermal de otros minerales de cobre. En Villamarchante, la olivenita aparece en asociaciones con azurita, lo que indica un ambiente de formación similar y un ciclo de alteración en el yacimiento.

      Estos minerales no solo tienen valor estético, sino que proporcionan información crucial sobre los procesos de oxidación y alteración hidrotermal que han tenido lugar en la región. El estudio de la azurita y la olivenita en Monte Horquera ofrece una ventana a la evolución geológica y mineralógica de este sector de la Comunidad Valenciana.
  `
    },
    {
      id: "Minerales Mina de San Vicente, Luchena",
      title: "Minerales Mina de San Vicente, Luchena",
      date: "2024-7-18",
      image: Mina,
      excerpt: "Un yacimiento mineral histórico conocido por su diversidad, incluyendo galena, esfalerita y calcopirita en la Mina de San Vicente, Luchena.",
      content: `
      La Mina de San Vicente, ubicada en Luchena, Castellón, es uno de los yacimientos históricos más importantes de la región, conocida por su riqueza en minerales como la galena, esfalerita y calcopirita. Este lugar ha sido una fuente significativa de extracción mineral desde la antigüedad.

      La galena, un sulfuro de plomo, es el mineral más abundante en la mina y destaca por su brillo metálico y su alta densidad. Se ha explotado durante siglos por su contenido de plomo y plata, y en la Mina de San Vicente se presenta en grandes masas cristalinas.

      La esfalerita, un sulfuro de zinc, se encuentra comúnmente asociada a la galena en este yacimiento. Reconocida por su color variable, que va del amarillo al marrón oscuro, la esfalerita es valorada no solo por su contenido en zinc, sino también por su belleza cristalina.

      La calcopirita, un mineral de cobre y hierro, completa el trío de minerales principales de la mina. Su color dorado metálico le ha valido el apodo de "oro de los tontos", y su presencia en Luchena es indicativa de la intensa actividad hidrotermal que originó el yacimiento.

      Estos minerales, además de ser valiosos para la minería, ofrecen una importante perspectiva sobre los procesos geológicos que dieron forma a la Mina de San Vicente. Su estudio continúa siendo clave para comprender la evolución minera de la región y sus características geológicas.
  `
    },
    {
      id: "Yacimiento Barbalimpia, Villarroya de los Pinares",
      title: "Yacimiento Barbalimpia, Villarroya de los Pinares",
      date: "Cretácico",
      image: Villarroya,
      excerpt: "Un importante yacimiento fósil en Villarroya de los Pinares, conocido por su diversidad de invertebrados marinos.",
      content: `
      El yacimiento fósil de Barbalimpia, ubicado en Villarroya de los Pinares, Teruel, es reconocido por su riqueza en fósiles del periodo Cretácico. Este sitio ha proporcionado una valiosa colección de fósiles de invertebrados marinos, incluyendo bivalvos, gasterópodos y amonites.

      Durante el Cretácico, esta región formaba parte de un ambiente marino, lo que explica la variedad de organismos que han sido preservados en el registro fósil. Los fósiles encontrados en Barbalimpia están excepcionalmente bien conservados, lo que permite a los paleontólogos estudiar la fauna marina de aquella época y comprender mejor la paleogeografía de la región.

      El yacimiento de Barbalimpia no solo es importante para la investigación científica, sino que también atrae a aficionados a la paleontología debido a su accesibilidad y a la calidad de los fósiles que se pueden encontrar.
  `
    },
    {
      id: "Iddingsita",
      title: "Iddingsita",
      date: "Sin fecha específica",
      image: iddgnisita,
      excerpt: "Un mineral de alteración originado a partir del olivino en ambientes volcánicos.",
      content: `
      La iddingsita es un mineral secundario, resultado de la alteración del olivino en rocas volcánicas, como basaltos y gabros. Esta transformación ocurre cuando el olivino se somete a procesos de hidratación y oxidación a bajas temperaturas, dando lugar a una mezcla compleja de minerales que suelen incluir serpentina, clorita y óxidos de hierro.

      Visualmente, la iddingsita se presenta como zonas terrosas o rojizas que sustituyen al olivino original, conservando a menudo la forma cristalina del mineral precursor. Aunque no es un mineral con valor económico directo, su presencia ofrece información valiosa sobre la historia geológica y las condiciones ambientales a las que se sometieron las rocas después de su formación.

      El estudio de la iddingsita ayuda a entender el ciclo evolutivo de los minerales, la interacción de la roca con fluidos hidrotermales y el impacto de procesos post-eruptivos en los entornos volcánicos. Así, este mineral es una clave para interpretar la dinámica geológica y la alteración mineralógica en zonas de actividad volcánica pasada.
  `,
    },
    {
      id: "Oliverita",
      title: "Oliverita",
      date: "Sin fecha específica",
      image: oliverita,
      excerpt: "Un mineral fosfatado poco común encontrado en zonas de oxidación de yacimientos.",
      content: `
      La oliverita es un mineral secundario de la clase de los fosfatos, formado habitualmente en las zonas de oxidación de diversos yacimientos minerales. Su composición incluye fosfatos hidratados de hierro y aluminio, y suele presentarse en forma de costras terrosas, masas nodulares o agregados finamente cristalinos.

      Debido a su rareza y a las condiciones geológicas específicas que requiere para formarse, la oliverita es de especial interés para coleccionistas y geólogos. Aunque no posee un valor económico significativo, su presencia aporta pistas valiosas sobre los procesos de alteración química y meteorítica que afectan a los minerales primarios, revelando el entorno geoquímico en el que se desarrolla.

      El estudio de la oliverita ayuda a comprender mejor los procesos mineralógicos que ocurren tras la formación de un depósito original, destacando el papel de las soluciones hidrotermales y la posterior oxidación y meteorización en la generación de nuevos minerales secundarios.
  `
    },
    {
      id: "Azurita",
      title: "Azurita",
      date: "Sin fecha específica",
      image: azuita,
      excerpt: "Un mineral de cobre conocido por su intenso color azul, formándose en las zonas de oxidación de depósitos de cobre.",
      content: `
      La azurita es un mineral secundario, compuesto por carbonato básico de cobre, reconocido por su característico e intenso color azul profundo. Se forma principalmente en zonas de oxidación de yacimientos de cobre, a menudo junto con la malaquita, su mineral hermano de color verde.

      Sus cristales pueden presentarse en formas prismáticas, tabulares o agregados masivos, y sus tonalidades la convierten en una pieza muy codiciada por coleccionistas y museos de todo el mundo. Aunque no es la principal fuente de cobre, la azurita suele indicarnos la presencia de este metal y aporta información sobre el grado de oxidación y la circulación de fluidos en el yacimiento.

      El estudio de la azurita proporciona datos sobre la evolución del depósito, las condiciones ambientales y los procesos geológicos que influyen en la mineralogía. Asimismo, su belleza y rareza la han convertido en un mineral apreciado tanto por su valor estético como científico.
  `
    },
    {
      "id": "Amatista",
      "title": "Amatista",
      "date": "Sin fecha específica",
      "image": amatista,
      "excerpt": "Una variedad de cuarzo de color violeta, valorada por su belleza y uso como gema.",
      "content": `
      La amatista es una variedad de cuarzo caracterizada por su distintivo color violeta, que varía desde tonos pálidos hasta un intenso púrpura. Esta tonalidad se debe a la presencia de trazas de hierro y a la exposición a la radiación natural, que altera la estructura cristalina del cuarzo puro.

      Los cristales de amatista suelen formarse en cavidades de rocas volcánicas, como las geodas de basalto, donde los fluidos ricos en sílice precipitan lentamente, permitiendo el crecimiento de cristales bien definidos y transparentes. Sus bellas tonalidades la han convertido en una de las piedras preciosas más apreciadas, utilizada en joyería y como objeto ornamental desde la antigüedad.

      Desde una perspectiva geológica, la amatista aporta información sobre las condiciones geoquímicas y térmicas durante la formación de las rocas que la albergan. Su estudio permite comprender mejor el ciclo de los silicatos, las condiciones de cristalización y el papel de elementos traza en la generación de colores y propiedades ópticas en los minerales.
  `
    },
    {
      id: "Azufre",
      title: "Azufre",
      date: "Sin fecha específica",
      image: azufre,
      excerpt: "Un elemento nativo conocido por su característico color amarillo brillante, presente en ambientes volcánicos y zonas de oxidación.",
      content: `
      El azufre es un elemento nativo que se encuentra comúnmente en su forma elemental, caracterizado por su color amarillo brillante y su aspecto cristalino. Se forma en diversas condiciones geológicas, particularmente en entornos volcánicos, fumarolas y en zonas de oxidación de yacimientos sulfurosos.

      Este mineral es suave, quebradizo y arde con facilidad, desprendiendo un olor característico. Históricamente, el azufre ha sido utilizado en la fabricación de pólvora, la industria química y como fungicida en la agricultura.

      El estudio del azufre en una región determinada puede proporcionar pistas sobre procesos geotérmicos, la presencia de minerales sulfídicos y la interacción con aguas hidrotermales. Por ello, es un mineral clave para comprender la dinámica interna del planeta, así como para el desarrollo de diversas aplicaciones industriales y agrícolas.
  `
    },
    {
      id: "Jacinto de Compostela",
      title: "Jacinto de Compostela (Variedad de Cuarzo)",
      date: "Sin fecha específica",
      image: compostela,
      excerpt: "Una variedad distintiva de cuarzo con tonalidades rojizas, comúnmente asociada a depósitos de yeso.",
      content: `
      El Jacinto de Compostela es una variedad de cuarzo reconocida por su característico color rojizo a marrón oscuro, resultado de la presencia de óxidos de hierro en su estructura. Estas diminutas geodas o cristales suelen encontrarse incrustadas en formaciones de yeso y margas, particularmente en yacimientos de origen triásico.

      Su forma prismática y el intenso tono rojizo lo convierten en un mineral muy apreciado por coleccionistas y aficionados a la mineralogía. A diferencia de otras variedades de cuarzo más comunes, el Jacinto de Compostela se forma en ambientes sedimentarios, asociados a la precipitación de minerales en medios evaporíticos.

      El estudio de este mineral aporta información sobre el contexto geológico y las condiciones químicas que favorecen la cristalización del cuarzo y la incorporación de impurezas metálicas. Además, su singular belleza y rareza lo convierten en una joya mineralógica representativa de los procesos geológicos que dieron forma a determinados paisajes.
  `
    },
    {
      id: "Calcita",
      title: "Calcita",
      date: "Sin fecha específica",
      image: calcita,
      excerpt: "Un mineral muy común compuesto de carbonato cálcico, presente en una amplia variedad de ambientes geológicos.",
      content: `
      La calcita es un mineral compuesto por carbonato de calcio (CaCO₃), reconocido por su hábito cristalino romboédrico y su amplia gama de colores, que van desde el incoloro hasta tonalidades blancas, amarillentas o incluso verdosas. Es un componente principal de las rocas sedimentarias calcáreas, como la caliza, y también puede formarse en ambientes hidrotermales, venas minerales o cuevas, dando lugar a formaciones como estalactitas y estalagmitas.

      Gracias a su abundancia, la calcita tiene gran importancia geológica, ya que participa en el ciclo del carbono y es un indicador de las condiciones químicas y ambientales en las que se forma. Desde un punto de vista económico, la calcita se emplea en la construcción, la industria química, la metalurgia, la agricultura y la fabricación de vidrio, entre otros usos.

      Su estudiada birefringencia (doble refracción) también ha sido relevante en el campo científico, permitiendo el desarrollo de instrumental óptico. De este modo, la calcita juega un papel esencial tanto en la interpretación de procesos geológicos como en múltiples aplicaciones industriales.
  `
    },
    {
      id: "Yeso",
      title: "Yeso",
      date: "Sin fecha específica",
      image: yeso,
      excerpt: "Un mineral compuesto por sulfato de calcio hidratado, común en ambientes sedimentarios y evaporíticos.",
      content: `
      El yeso es un mineral formado por sulfato de calcio dihidratado (CaSO₄·2H₂O), caracterizado por su baja dureza, su color generalmente blanco o transparente y su hábito cristalino tabular o fibroso. Es un mineral común en ambientes sedimentarios donde la evaporación del agua favorece la precipitación de sulfatos, así como en depósitos volcánicos y en formaciones geológicas evaporíticas.

      Debido a su facilidad de extracción y a sus propiedades físicas, el yeso tiene múltiples aplicaciones industriales. Se utiliza en la fabricación de cemento, paneles de yeso, escayolas, fertilizantes y en la industria alimentaria como aditivo. Además, es de gran interés geológico, ya que su presencia indica cambios en el nivel del agua, la evaporación y las condiciones climáticas del pasado.

      El estudio del yeso también ofrece información sobre la diagenésis, los procesos sedimentarios y el ciclo geoquímico del azufre y el calcio en el medio ambiente. Su facilidad para transformarse bajo diferentes condiciones de temperatura y presión lo convierte en un mineral clave para comprender la evolución de diversos ambientes geológicos.
  `
    },
    {
      "id": "Sílex",
      "title": "Sílex",
      "date": "Sin fecha específica",
      "image": silex,
      "excerpt": "Una variedad criptocristalina de cuarzo, conocida también como pedernal, utilizada históricamente para herramientas y armas.",
      "content": `
      El sílex, también llamado pedernal, es una forma criptocristalina de dióxido de silicio (SiO₂), similar a la calcedonia, pero con una textura más homogénea y compacta. Su estructura interna, formada por diminutos cristales de cuarzo, le otorga una dureza y resistencia considerables.

      Se encuentra comúnmente en nódulos o capas incrustadas en rocas sedimentarias, especialmente en calizas y margas. Su fractura concoidea produce bordes muy afilados, lo que fue aprovechado por las sociedades prehistóricas para fabricar herramientas de corte, puntas de flecha y lanzas.

      Desde el punto de vista geológico, el estudio del sílex ofrece información sobre los procesos sedimentarios y químicos que dan lugar a la silicificación en entornos marinos y continentales. Además, su importancia histórica y arqueológica es fundamental para entender la evolución tecnológica humana, ya que el dominio de su talla marcó un hito en el desarrollo cultural de numerosas civilizaciones.
  `
    },
    {
      "id": "Cuarzo Masivo",
      "title": "Cuarzo Masivo",
      "date": "Sin fecha específica",
      "image": cuarzo,
      "excerpt": "Una forma compacta y sin caras cristalinas definidas de dióxido de silicio, muy abundante en la corteza terrestre.",
      "content": `
      El cuarzo masivo es una variedad de dióxido de silicio (SiO₂) que se presenta en forma de masas compactas y sin cristales visibles a simple vista. A diferencia de los cuarzos bien cristalizados, el cuarzo masivo carece de las típicas caras y geometrías definidas, adoptando habitualmente una apariencia homogénea y opaca, en tonos blancos, grises o ligeramente translúcidos.

      Esta forma de cuarzo se origina en una amplia gama de entornos geológicos, desde vetas hidrotermales hasta rocas ígneas y sedimentarias. Debido a su abundancia, se utiliza en múltiples aplicaciones, incluyendo la construcción, la industria del vidrio y la cerámica.

      Desde un punto de vista geológico, el cuarzo masivo es un indicador de las condiciones químicas y físicas del medio en el que cristalizó. Su estudio permite entender la evolución de las rocas que lo contienen, las interacciones con fluidos ricos en sílice y la historia tectónica y térmica de la región donde se encuentra.
  `
    },
    {
      "id": "Aragonito",
      "title": "Aragonito",
      "date": "Sin fecha específica",
      "image": argonito,
      "excerpt": "Un polimorfo del carbonato de calcio, con cristales prismáticos y hábito acicular, asociado a ambientes marinos y geotermales.",
      "content": `
      El aragonito es un mineral compuesto por carbonato de calcio (CaCO₃), igual que la calcita, pero con una estructura cristalina distinta. Se presenta habitualmente en cristales prismáticos, delgados y a menudo aciculares, que pueden agruparse en agregados radiados o coralinos. Su color varía entre el blanco, el transparente y tonalidades amarillas o marrones, dependiendo de las impurezas.

      Se forma en una amplia variedad de ambientes geológicos, desde depósitos marinos —donde participa en la formación del esqueleto de corales y conchas de moluscos— hasta zonas hidrotermales y cuevas, en las que las aguas ricas en calcio precipitan el mineral. Con el tiempo, el aragonito tiende a transformarse en calcita, más estable bajo condiciones superficiales.

      El estudio del aragonito resulta clave para comprender procesos biogénicos, sedimentarios y geotermales. Además, su presencia y distribución ofrecen indicios sobre la química del agua, la temperatura, la presión y las condiciones ambientales del pasado, haciendo de este mineral un valioso indicador del entorno geológico y climático.
  `
    },
    {
      id: "Mina de Ocre del Faro del Albir",
      title: "Mina de Ocre del Faro del Albir",
      date: "Sin fecha específica",
      image: faro,
      excerpt: "Un antiguo yacimiento en la costa alicantina, destacado por la extracción de ocre.",
      content: `
      La Mina de Ocre del Faro del Albir, situada en la costa de Alicante, destaca por sus antiguas labores de extracción de ocre, un material terroso compuesto principalmente por óxidos de hierro y arcillas. Este recurso, con tonalidades que varían del amarillo al marrón rojizo, se formó gracias a procesos de meteorización y oxidación de minerales ferrosos en el entorno litoral.

      A lo largo de la historia, el ocre extraído en esta mina fue valorado por su uso como pigmento en la elaboración de pinturas, recubrimientos y artesanías. Su riqueza cromática y la facilidad de obtención lo convirtieron en un recurso muy apreciado para usos tanto locales como regionales.

      El estudio de la Mina de Ocre del Faro del Albir proporciona información sobre el contexto geológico costero, las condiciones ambientales y los procesos de alteración que dieron lugar a la formación de este depósito. Además, su exploración y documentación sirven para comprender la interacción histórica entre las comunidades humanas y los recursos naturales del Mediterráneo.
  `
    },
    {
      id: "Dolomita",
      title: "Dolomita",
      date: "Sin fecha específica",
      image: dolomita,
      excerpt: "Un mineral compuesto por carbonato doble de calcio y magnesio, frecuente en rocas sedimentarias.",
      content: `
      La dolomita es un mineral formado por carbonato de calcio y magnesio (CaMg(CO₃)₂). Presenta un hábito cristalino romboédrico similar al de la calcita, aunque suele ser algo más dura y menos soluble en ácido clorhídrico diluido. Este mineral se encuentra con frecuencia en rocas sedimentarias, como dolomías, que se originan generalmente por la alteración de calizas ricas en magnesio.

      Sus colores suelen variar entre blanco, gris, crema o incluso tonos rosados, dependiendo de las impurezas presentes. La dolomita es de importancia económica, ya que se utiliza en la industria siderúrgica, en la fabricación de materiales refractarios, fertilizantes y en la construcción.

      Desde el punto de vista geológico, el estudio de la dolomita ayuda a comprender procesos de diagénesis y las condiciones químicas del medio sedimentario. Su presencia indica cambios en la composición química del agua intersticial de los sedimentos marinos y continentales, aportando información valiosa sobre la historia geológica de una región.
  `
    }
  ];

  const markers = [
    { id: 1, position: [38.4526526, -0.7715992], description: 'Loma Badá, ubicada en la localidad de Petrer, en la provincia de Alicante, es conocida por sus yacimientos de minerales, en particular de aragonito.', image: Petrer, title: "Argonitos en Loma Badá, Petrer", moreInfoLink: "yacimientos-mineral/Argonitos en Loma Badá, Petrer" },
    { id: 2, position: [38.5769537, -0.2589064], description: 'A lo largo de la carretera que conecta Orxeta y Finestrat, en la provincia de Alicante, se encuentran ricos yacimientos de Jacintos de Compostela.', image: Finestrat, title: "Jacintos de compostela en la carretera de Orxeta-Finestrat", moreInfoLink: "yacimientos-mineral/Jacintos de compostela en la carretera de Orxeta-Finestrat" },
    { id: 3, position: [38.4960296126089, -0.5824822022584811], description: 'La mina San Francisco, ubicada en Tibi, Alicante, es un sitio de notable relevancia debido a sus ricos yacimientos de azufre.', image: Tibi, title: "Azufre en la mina San Francisco, Tibi", moreInfoLink: "yacimientos-mineral/Azufre en la mina San Francisco, Tibi" },
    { id: 4, position: [38.5615094, -0.2186378], description: 'El depósito de Keuper, situado a lo largo de la carretera que conecta Orxeta y Finestrat en la provincia de Alicante, es conocido por sus yacimientos de calcita.', image: Orxeta, title: "Calcita en el deposito de Keuper, Carretera Orxeta-Finestrat", moreInfoLink: "yacimientos-mineral/Calcita en el deposito de Keuper, Carretera Orxeta-Finestrat" },
    { id: 5, position: [39.2391161, -1.0700520], description: 'El Cerro de Agras, situado en Cofrentes, Valencia, es un volcán de gran importancia geológica, conocido por sus yacimientos de olivino e iddingsita.', image: Cofrentes, title: "Olivino y Iddingsita Volcán Cerro de Agras, Cofrentes", moreInfoLink: "yacimientos-mineral/Olivino y Iddingsita Volcán Cerro de Agras, Cofrentes" },
    { id: 6, position: [39.5376810, -0.6282114], description: 'Un yacimiento mineral excepcional con abundancia de azurita y olivenita en Monte Horquera, Villamarchante.', image: azuita, title: "Azurita y Olivenita Monte Horquera, Villamarchante", moreInfoLink: "yacimientos-mineral/Azurita y Olivenita Monte Horquera, Villamarchante" },
    { id: 7, position: [40.10488720827497, -0.3024352921829018], description: ' Un yacimiento mineral histórico conocido por su diversidad, incluyendo galena, esfalerita y calcopirita en la Mina de San Vicente, Luchena.', image: Mina, title: "Minerales Mina de San Vicente, Luchena", moreInfoLink: "yacimientos-mineral/Minerales Mina de San Vicente, Luchena" },
    { id: 8, position: [40.5004477, -0.6858980], description: 'Un importante yacimiento fósil en Villarroya de los Pinares, conocido por su diversidad de invertebrados marinos.', image: Villarroya, title: "Yacimiento Barbalimpia, Villarroya de los Pinares", moreInfoLink: "yacimientos-mineral/Yacimiento Barbalimpia, Villarroya de los Pinares" },
    { id: 9, position: [38.5630325, -0.0515615], description: 'Un antiguo yacimiento en la costa alicantina, destacado por la extracción de ocre.', image: faro, title: "Mina de Ocre del Faro del Albir", moreInfoLink: "yacimientos-mineral/Mina de Ocre del Faro del Albir" }
  ];

  /*
   {
      id: "Ocre",
      title: "Ocre",
      date: "Sin fecha específica",
      image: "Ocre",
      excerpt: "Una mezcla natural de óxidos de hierro y arcillas, empleada históricamente como pigmento.",
      content: `
      El ocre es un material terroso constituido principalmente por óxidos de hierro hidratados y mezclas de arcillas. Presenta tonalidades que varían desde el amarillo hasta el marrón rojizo, dependiendo de su composición y del grado de hidratación del hierro. Se origina tanto en ambientes sedimentarios como en zonas de meteorización de minerales ferrosos.

      A lo largo de la historia, el ocre ha sido utilizado por diversas culturas como pigmento en pinturas rupestres, cerámicas y manifestaciones artísticas debido a su fácil extracción, estabilidad y colorido. Además, en la actualidad se sigue empleando en la industria de la pintura y la decoración por su tonalidad cálida y terrosa.

      Desde el punto de vista geológico, el estudio del ocre proporciona información sobre los procesos de oxidación, meteorización y alteración de minerales que tienen lugar en la corteza terrestre. Asimismo, su distribución geográfica e historia de uso reflejan la relación entre el ser humano y los recursos naturales del entorno.
  `
    }*/
  return (
    <Router basename="/">
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/inicio-yacimientos-minerales" element={<LandingPage />} />
        <Route path="/blog" element={<BlogPage posts={posts} />} />
        <Route path="/blog-yacimientos-minerales" element={<BlogPage posts={posts} />} />
        <Route path="/yacimientos-mineral/:id" element={<BlogPost posts={posts} />} />
        <Route path="/mapa-yacimientos-minerales" element={<MapPage markers={markers} />} />
        <Route path="/aviso-legal" element={<AvisoLegal />} />
        <Route path="/terminos-condiciones" element={<TerminosCondiciones />} />
        <Route path="/politica-privacidad" element={<PoliticaPrivacidad />} />
        <Route path="/politica-cookies" element={<PoliticaCookies />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
