import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';
import logo from '../asserts/mineral.png'

const Navbar = () => {
    return (
        <nav className="navbar">
            <div className='icono'>
                <img src={logo} alt='Logo de GEMY - Grupo de Exploración de Minerales' className='logoIMG'></img>
                <h1>GEMY</h1>
            </div>
            <ul>
                <li><Link to="/inicio-yacimientos-minerales">Inicio</Link></li>
                <li><Link to="/blog-yacimientos-minerales">Blog</Link></li>
                <li><Link to="/mapa-yacimientos-minerales">Mapa</Link></li>
            </ul>
        </nav>
    );
};

export default Navbar;
