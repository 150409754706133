import React from 'react';

const PoliticaCookies = () => {
    return (
        <main className="blog-list">
            <h1>Política de Cookies</h1>
            <section>
                <h2>¿Qué son las Cookies?</h2>
                <p>
                    Las cookies son archivos que se almacenan en el dispositivo del usuario cuando visita un sitio web
                    para mejorar la experiencia de navegación.
                </p>
            </section>

            <section>
                <h2>Tipos de Cookies que Utilizamos</h2>
                <ul>
                    <li><strong>Cookies Propias:</strong> Gestionadas por nosotros para mejorar la experiencia del usuario.</li>
                    <li><strong>Cookies de Terceros:</strong> Gestionadas por proveedores externos (ej: Google Analytics).</li>
                    <li><strong>Cookies de Sesión:</strong> Se eliminan al cerrar el navegador.</li>
                    <li><strong>Cookies Persistentes:</strong> Permanecen durante un tiempo determinado o hasta que el usuario las borre.</li>
                </ul>
            </section>

            <section>
                <h2>Finalidad de las Cookies</h2>
                <p>
                    Permiten recordar preferencias, analizar el uso del sitio y ofrecer contenido personalizado.
                </p>
            </section>

            <section>
                <h2>Consentimiento</h2>
                <p>
                    Al acceder a la web, se muestra un aviso sobre el uso de cookies.
                    El usuario puede aceptar, rechazar o configurar las cookies.
                </p>
            </section>

            <section>
                <h2>Cómo Deshabilitar las Cookies</h2>
                <p>
                    El usuario puede configurar su navegador para eliminar o rechazar cookies.
                    Esto puede afectar el funcionamiento de algunas partes del sitio.
                </p>
            </section>
        </main>
    );
};

export default PoliticaCookies;
