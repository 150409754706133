import React from 'react';
import '../styles/Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer id="contact" className="footer">
            <p>
                &copy; 2024 Grupo de Exploración de Minerales y Yacimientos. Correo:{' '}
                <a href="mailto:info.grupogemy@gmail.com">info.grupogemy@gmail.com</a>. All Rights Reserved.
            </p>
            <ul className="footer-legal-links">
                <li><Link to="/aviso-legal" rel="noopener noreferrer">Aviso Legal</Link></li>
                <li><Link to="/terminos-condiciones" rel="noopener noreferrer">Términos y Condiciones</Link></li>
                <li><Link to="/politica-privacidad" rel="noopener noreferrer">Política de Privacidad</Link></li>
                <li><Link to="/politica-cookies" rel="noopener noreferrer">Política de Cookies</Link></li>
            </ul>
        </footer>
    );
};

export default Footer;
