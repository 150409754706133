import React from 'react';
import '../styles/BlogList.css';

const PoliticaPrivacidad = () => {
    return (
        <main className="blog-list">
            <h1>Política de Privacidad</h1>
            <section className="blog-posts">
                <h2 className="blog-post-title">Responsable del Tratamiento</h2>
                <p className="blog-post-excerpt">
                    El responsable del tratamiento de los datos es [Nombre del Titular], con datos de contacto indicados en el Aviso Legal.
                </p>
            </section>
            <section className="blog-posts">
                <h2 className="blog-post-title">Finalidad del Tratamiento</h2>
                <p className="blog-post-excerpt">
                    Los datos se usarán para gestionar servicios solicitados, enviar comunicaciones (si el usuario da su consentimiento)
                    y mejorar la experiencia en el sitio.
                </p>
            </section>

            <section className="blog-posts">
                <h2 className="blog-post-title">Legitimación del Tratamiento</h2>
                <p className="blog-post-excerpt">
                    La base legal es el consentimiento del usuario y, en algunos casos, el interés legítimo o el cumplimiento de una obligación legal.
                </p>
            </section>

            <section className="blog-posts">
                <h2 className="blog-post-title">Comunicación de Datos a Terceros</h2>
                <p className="blog-post-excerpt">
                    No se comunicarán datos a terceros salvo obligación legal o necesidad para la prestación del servicio.
                </p>
            </section>

            <section className="blog-posts">
                <h2 className="blog-post-title">Derechos del Usuario</h2>
                <p className="blog-post-excerpt">
                    El usuario puede ejercer sus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento
                    y portabilidad escribiendo a [Email de contacto].
                </p>
            </section>
        </main>
    );
};

export default PoliticaPrivacidad;
