import React from 'react';
import '../styles/BlogList.css';

const TerminosCondiciones = () => {
    return (
        <main className="blog-list">
            <h1>Términos y Condiciones</h1>
            <section className="blog-posts">
                <h2 className="blog-post-title">Objeto y Ámbito de Aplicación</h2>
                <p className="blog-post-content">
                    Estos términos y condiciones regulan el acceso, navegación y uso del sitio web <a href="https://yacimientosminerales.com">yacimientosminerales.com</a>.
                </p>
            </section>

            <section className="blog-posts">
                <h2 className="blog-post-title">Acceso y Uso del Sitio Web</h2>
                <p className="blog-post-content">
                    El acceso es gratuito. Algunos servicios pueden requerir registro. El usuario se compromete a proporcionar información veraz y mantenerla actualizada.
                </p>
            </section>

            <section className="blog-posts">
                <h2 className="blog-post-title">Contenido y Limitaciones de Uso</h2>
                <p className="blog-post-content">
                    El usuario se obliga a utilizar el sitio de conformidad con la ley, la moral y el orden público. Queda prohibido introducir virus u otros componentes dañinos.
                </p>
            </section>

            <section className="blog-posts">
                <h2 className="blog-post-title">Exoneración de Responsabilidad</h2>
                <p className="blog-post-content">
                    El Titular no será responsable de la exactitud o integridad del contenido ni de las decisiones que el usuario tome basándose en él.
                </p>
            </section>

            <section className="blog-posts">
                <h2 className="blog-post-title">Enlaces a Terceros</h2>
                <p className="blog-post-content">
                    El sitio puede contener enlaces a páginas de terceros. El Titular no asume responsabilidad por dichos contenidos o servicios.
                </p>
            </section>

            <section className="blog-posts">
                <h2 className="blog-post-title">Modificación de las Condiciones</h2>
                <p className="blog-post-content">
                    El Titular se reserva el derecho de modificar las condiciones en cualquier momento, publicando las nuevas en el sitio web.
                </p>
            </section>
        </main>
    );
};

export default TerminosCondiciones;
