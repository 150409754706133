import React from 'react';
import '../styles/BlogList.css';

const AvisoLegal = () => {
    return (
        <main className="blog-list">
            <h1>Aviso Legal</h1>
            <section className="blog-posts">
                <h2 className="blog-post-title">Objeto</h2>
                <p className="blog-post-excerpt">
                    El presente aviso legal regula el uso del sitio web <a href="https://yacimientosminerales.com">https://yacimientosminerales.com</a>.
                    El acceso y la navegación en el sitio implican la aceptación de las disposiciones incluidas en este Aviso Legal.
                </p>
            </section>

            <section className="blog-posts">
                <h2 className="blog-post-title">Condiciones de Uso</h2>
                <p className="blog-post-excerpt">
                    El usuario se compromete a hacer un uso adecuado del contenido y los servicios que el Titular ofrece a través del sitio web.
                    Queda prohibido su uso con fines ilícitos o contrarios a la legislación vigente.
                </p>
            </section>

            <section className="blog-posts">
                <h2 className="blog-post-title">Responsabilidad</h2>
                <p className="blog-post-excerpt">
                    El Titular se reserva el derecho a realizar modificaciones en el sitio web sin previo aviso.
                    No se hace responsable de los daños o perjuicios derivados del uso del sitio o de la información contenida.
                </p>
            </section>

            <section className="blog-posts">
                <h2 className="blog-post-title">Propiedad Intelectual e Industrial</h2>
                <p className="blog-post-excerpt">
                    Todos los contenidos (textos, imágenes, gráficos, diseños, códigos, logotipos, marcas) están protegidos por derechos
                    de propiedad intelectual e industrial.
                </p>
            </section>
        </main>
    );
};

export default AvisoLegal;
